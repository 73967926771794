import { EPlatforms } from 'platformsConstants';
import { TemplateType } from 'redux/urlTemplate/types';

export interface OrganizationContext {
  organizationId: string;
  workspaceId: string;
}

export interface GetRunHistoryDTO extends OrganizationContext {
  platforms: EPlatforms[];
  accountIds: string[];
  dateFrom: Date;
  dateTo: Date;
  sortOrder: SORT_ORDER;
  sortBy: SORT_BY;
  offset: number;
  limit: number;
}

export interface SearchInfo
  extends Omit<GetRunHistoryDTO, 'organizationId' | 'workspaceId'> {
  totalCount: number;
  earliestDate: string;
}

export interface RunHistoryData {
  info: SearchInfo;
  data: RunSummary[];
}

export interface Pagination {
  offset: number;
  limit: number;
  totalCount: number;
}

export interface RunSummary extends UpdateStatusesCounters {
  jobId: string;
  adAccountId: string;
  accountName: string;
  platform: EPlatforms;
  jobSource: JOB_SOURCE;
  jobStatus: string | null;
  createdAt: string;
  total: number;
}

export type UpdateStatusesCounters = Record<UPDATE_STATUS, number>;

export enum UPDATE_STATUS {
  UPDATED = 'updated',
  SKIPPED = 'skipped',
  NOT_SUPPORTED = 'not_supported',
  PARTIALLY_UPDATED = 'partially_updated',
  ERROR = 'error',
}

export interface GetRunHistoryParams {
  organization_id: string;
  platforms?: EPlatforms[];
  accountIds?: string[];
  dateFrom?: string;
  dateTo?: string;
  sortOrder?: SORT_ORDER;
  sortBy?: SORT_BY;
  offset?: number;
  limit?: number;
}

export enum SORT_ORDER {
  ASC = 1,
  DESC = -1,
}

export type SORT_BY = SORT_KEY | UPDATE_STATUS;

export enum SORT_KEY {
  CREATED_AT = 'createdAt',
  ACCOUNT_NAME = 'accountName',
  TOTAL = 'total',
}

export enum JOB_SOURCE {
  AUTO = 'auto',
  MANUAL = 'manual',
  DROP_JOB = 'drop_job',
}

export interface HistoryAdAccount {
  accountId: string;
  accountName: string;
  platform: EPlatforms;
}

export enum LINKEDIN_SORT_KEYS {
  CAMPAIGN_GROUP_NAME = 'campaignGroupName',
  CAMPAIGN_NAME = 'campaignName',
  CREATIVE_NAME = 'creativeName',
}

export enum GOOGLE_SORT_KEYS {
  CAMPAIGN_NAME = 'campaignName',
  AD_GROUP_NAME = 'adGroupName',
  AD_NAME = 'adName',
}

export type PLATFORM_SORT_KEYS = LINKEDIN_SORT_KEYS | GOOGLE_SORT_KEYS;

export interface BaseGetRunDetailsDTO extends OrganizationContext {
  accountId: string;
  jobId: string;
  statuses: UPDATE_STATUS[];
  sortOrder: SORT_ORDER;
  offset: number;
  limit: number;
}

export interface LinkedinGetRunDetailsDTO extends BaseGetRunDetailsDTO {
  campaignGroupIds: string[];
  campaignIds: string[];
  creativeIds: string[];
  sortBy: LINKEDIN_SORT_KEYS;
}

export interface GoogleGetRunDetailsDTO extends BaseGetRunDetailsDTO {
  campaignIds: string[];
  adGroupIds: string[];
  adIds: string[];
  sortBy: GOOGLE_SORT_KEYS;
}

export interface GetLinkedinRunDetailsParams {
  organization_id: string;
  accountId: string;
  jobId: string;
  statuses: UPDATE_STATUS[];
  sortOrder: SORT_ORDER;
  offset: number;
  limit: number;
  campaignGroupIds: string[];
  campaignIds: string[];
  creativeIds: string[];
  sortBy: LINKEDIN_SORT_KEYS;
}

export interface GetGoogleRunDetailsParams {
  organization_id: string;
  accountId: string;
  jobId: string;
  statuses: UPDATE_STATUS[];
  sortOrder: SORT_ORDER;
  offset: number;
  limit: number;
  campaignIds: string[];
  adGroupIds: string[];
  adIds: string[];
  sortBy: GOOGLE_SORT_KEYS;
}

export interface RunDetailsData<T extends BaseGetRunDetailsDTO, K> {
  info: Omit<T, 'organizationId' | 'workspaceId' | 'jobId' | 'accountId'> & {
    totalCount: number;
  };
  data: RunDetailsItem<K>[];
}

export interface RunDetailsItem<T> {
  jobId: string;
  createdAt: string;
  jobSource: JOB_SOURCE;
  adAccountRessourceId: string;
  parsedAsset?: T;
  platform: EPlatforms;
  status: UPDATE_STATUS;
  fromUtm?: string;
  toUtm?: string;
  errorType?: CREATIVE_ERROR_TYPE;
  postUpdateMetadata?: {
    creativesWithSamePost: CampaignCreative[];
    deletedTrackingTemplateParams: TemplateType;
  };
}
export type CampaignCreative = {
  campaign: string;
  id: string;
  account: string;
  name?: string;
};

export type LinkedinParsedAsset = {
  accountName: string;
  campaignGroupId: string;
  campaignId: string;
  creativeId: string;
  campaignGroupName: string;
  campaignName: string;
  creativeName: string;
  campaignFormat: string;
};

export type GoogleParsedAsset = {
  accountName: string;
  campaignId: string;
  adGroupId: string;
  adId: string;
  campaignName: string;
  adGroupName: string;
  adName: string;
  campaignType: string;
};

export enum CREATIVE_ERROR_TYPE {
  NO_LANDING_PAGE = 'NO_LANDING_PAGE',
  NO_URL_TEMPLATE = 'NO_URL_TEMPLATE',
  NOT_PARSED_LANDING_PAGE = 'NOT_PARSED_LANDING_PAGE',
  NOT_CALCULATED_URL_TEMPLATE = 'NOT_CALCULATED_URL_TEMPLATE',
  UNABLE_TO_RESET = 'UNABLE_TO_RESET',
  URL_TOO_LONG = 'URL_TOO_LONG',
  NO_PERMISSIONS = 'NO_PERMISSIONS',
  NO_CREATIVE_FOUND = 'NO_CREATIVE_FOUND',
  INVALID_HREF = 'INVALID_HREF',
  CONFLICT = 'CONFLICT',
  FAILED_DEPENDENCY = 'FAILED_DEPENDENCY',
  SERVER_ERROR = 'SERVER_ERROR',
  UNKNOWN = 'UNKNOWN',
}

export interface RunDetailsMetaDTO {
  organization_id: string;
  accountId: string;
  jobId: string;
  createdAt: string;
}

export type RunDetailsMetaData<T> = {
  nextJobInfo: JobBriefInfo;
  prevJobInfo: JobBriefInfo;
  meta: T;
};

export type JobBriefInfo = {
  jobId: string | null;
  createdAt: string | null;
  jobSource: JOB_SOURCE | null;
};

export interface LinkedinRunDetailsFilter {
  uniqueCampaignGroups: { id: string; name: string }[];
  uniqueCampaigns: { id: string; name: string }[];
  uniqueCreatives: { id: string; name: string }[];
  summary: MetaSummary;
}

export interface GoogleRunDetailsFilter {
  uniqueCampaigns: { id: string; name: string }[];
  uniqueAdGroups: { id: string; name: string }[];
  uniqueAds: { id: string; name: string }[];
  summary: MetaSummary;
}
export type MetaSummary =
  | UpdateStatusesCounters
  | {
      total?: number;
    };

export enum CAMPAIGN_FORMATS {
  TEXT_AD = 'TEXT_AD',
  SPOTLIGHT = 'SPOTLIGHT',
  SINGLE_VIDEO = 'SINGLE_VIDEO',
  STANDARD_UPDATE = 'STANDARD_UPDATE',
  CAROUSEL = 'CAROUSEL',
  FOLLOW_COMPANY = 'FOLLOW_COMPANY',
  JOBS = 'JOBS',
  SPONSORED_INMAIL = 'SPONSORED_INMAIL',
  SPONSORED_MESSAGE = 'SPONSORED_MESSAGE',
  SPONSORED_UPDATE_EVENT = 'SPONSORED_UPDATE_EVENT',
  SPONSORED_UPDATE_NATIVE_DOCUMENT = 'SPONSORED_UPDATE_NATIVE_DOCUMENT',
  UNSUPPORTED = 'UNSUPPORTED',
}

export enum CAMPAIGN_TYPES {
  DISCOVERY = 'DISCOVERY',
  DISPLAY = 'DISPLAY',
  HOTEL = 'HOTEL',
  LOCAL = 'LOCAL',
  LOCAL_SERVICES = 'LOCAL_SERVICES',
  MULTI_CHANNEL = 'MULTI_CHANNEL',
  PERFORMANCE_MAX = 'PERFORMANCE_MAX',
  SEARCH = 'SEARCH',
  SHOPPING = 'SHOPPING',
  SMART = 'SMART',
  UNKNOWN = 'UNKNOWN',
  UNSPECIFIED = 'UNSPECIFIED',
  VIDEO = 'VIDEO',
}

export type NotSupportedFormats = {
  [EPlatforms.GOOGLE]: string[];
  [EPlatforms.LINKEDIN]: CAMPAIGN_FORMATS[];
};

export type PlatformFormat<P extends EPlatforms> =
  P extends keyof NotSupportedFormats ? NotSupportedFormats[P] : never;
