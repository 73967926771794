import { FC } from 'react';
import { Fragment } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { LinkedinParsedAsset, RunDetailsItem } from 'redux/runHistory/types';
import { UPDATE_STATUS } from 'redux/runHistory/types';

import { CampaignFormatLabels, ErrorDescription } from '../../constants';

type DescriptionCellProps = {
  result: RunDetailsItem<LinkedinParsedAsset>;
};

export const DescriptionCell: FC<DescriptionCellProps> = ({ result }) => {
  const { toUtm = '', status, errorType, postUpdateMetadata } = result;
  const { deletedTrackingTemplateParams: deletedParams } =
    postUpdateMetadata || {};

  const isError = status === UPDATE_STATUS.ERROR && errorType;
  const isSkipped = status === UPDATE_STATUS.SKIPPED;
  const isPartiallyUpdated = status === UPDATE_STATUS.PARTIALLY_UPDATED;
  const isNotSupported = status === UPDATE_STATUS.NOT_SUPPORTED;

  const { t } = useTranslation();

  if (isError) {
    const fullString = t(ErrorDescription[errorType]) || errorType;

    return (
      <Typography color="error.main" fontWeight={700}>
        {fullString}
      </Typography>
    );
  }

  if (isNotSupported) {
    return (
      <Typography>
        {t('details.not_supported_description')}{' '}
        {t(
          CampaignFormatLabels[result?.parsedAsset?.campaignFormat as string]
        ) || result?.parsedAsset?.campaignFormat}
      </Typography>
    );
  }

  let url;
  let params;
  let domain = '';

  if (!toUtm) return <Typography>{t('details.no_params')}</Typography>;

  try {
    url = new URL(toUtm);
    params = new URLSearchParams(url?.search);
    domain = url?.origin;
  } catch (error) {
    params = new URLSearchParams(toUtm);
  }

  return (
    <>
      {!!domain && <Typography>{domain}?</Typography>}
      {Array.from(params).map(([key, value], index, arr) => (
        <Typography key={key} component="span">
          <Typography
            fontWeight={500}
            color={isSkipped ? 'inherit' : 'primary.main'}
            component="span"
          >
            {key}
          </Typography>
          {'='}
          <Typography
            fontWeight={500}
            color={isSkipped ? 'inherit' : 'success.light'}
            component="span"
            fontStyle="italic"
          >
            {value}
          </Typography>
          {index + 1 !== arr.length && '\u{200B}&'}
        </Typography>
      ))}
      {isPartiallyUpdated && (
        <>
          <Typography mt="15px">
            {t('details.ignored_params')}{' '}
            <Typography color="error" component="span">
              {deletedParams &&
                Object.entries(deletedParams).map(
                  ([key, value], index, arr) => (
                    <Fragment key={key}>
                      {key}={value.value}
                      {index + 1 !== arr.length && '\u{200B}&'}
                    </Fragment>
                  )
                )}
            </Typography>
          </Typography>

          <Typography>{t('details.partially_tagged_reason')}</Typography>
        </>
      )}
    </>
  );
};
